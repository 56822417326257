import { trackEvent, TrackerData } from "@finn-no/pulse-sdk";

interface CreativeProps extends TrackerData {
	adCreative: string;
	adPosition: string;
	verticalName: string;
	platform?: string;
	subvertical?: string;
}
interface CreativeLoadViewProps extends CreativeProps {}

export interface CreativeClickProps extends CreativeProps {
	targetId: string;
	provider: string;
}

function getObjectData({ adCreative, adPosition }: { adCreative: string; adPosition: string }): TrackerData["object"] {
	return {
		id: adCreative,
		type: "DisplayAd",
		adType: "Content",
		adFormat: "Single-view",
		adPosition,
		adCreative,
	};
}

export function logCreativeLoad(props: CreativeLoadViewProps) {
	const { adCreative, adPosition, verticalName, subvertical, name = "Display ad" } = props;

	trackEvent({
		type: "Load",
		name,
		object: getObjectData({
			adCreative,
			adPosition,
		}),
		vertical: {
			name: verticalName,
			subVertical: subvertical,
		},
	});
}

export function logCreativeView(props: CreativeLoadViewProps) {
	const { adCreative, adPosition, verticalName, subvertical, name = "Display ad" } = props;

	trackEvent({
		type: "View",
		name,
		object: getObjectData({
			adCreative,
			adPosition,
		}),
		vertical: {
			name: verticalName,
			subVertical: subvertical,
		},
	});
}

export function logCreativeClick(props: CreativeClickProps) {
	const { adCreative, adPosition, verticalName, subvertical, name = "Display ad", targetId, provider } = props;

	trackEvent({
		type: "Click",
		intent: "Open",
		name,
		object: getObjectData({
			adCreative,
			adPosition,
		}),
		vertical: {
			name: verticalName,
			subVertical: subvertical,
		},
		target: {
			id: adCreative,
			name: "Sponsored Content Article",
			url: targetId,
			referralCommission: false,
			type: "ExternalContent",
			provider: provider,
		},
	});
}
